"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.removeToken = removeToken;
exports.setToken = setToken;
exports.setYHToken = setYHToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _settings = _interopRequireDefault(require("@/settings"));
var TokenKey = _settings.default.TokenKey;
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token, rememberMe) {
  if (rememberMe) {
    return _jsCookie.default.set(TokenKey, token, {
      expires: _settings.default.tokenCookieExpires
    });
  } else return _jsCookie.default.set(TokenKey, token);
}
function setYHToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}