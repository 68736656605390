"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var contextMenuItem = {
  props: {
    menuItem: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  methods: {
    handleClick: function handleClick(e) {
      this.menuItem.callback.call(null, e, this.menuItem);
    }
  },
  render: function render(h) {
    return h("li", {
      "class": "v-contextmenu-li",
      "attrs": {
        "tabindex": "-1"
      },
      "on": {
        "click": this.handleClick
      }
    }, [this.menuItem.name]);
  }
};
var _default2 = contextMenuItem;
exports.default = _default2;