"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _dayjs = _interopRequireDefault(require("dayjs"));
var _weekday = _interopRequireDefault(require("dayjs/plugin/weekday"));
_dayjs.default.extend(_weekday.default);
var _default = {
  name: 'DateTime',
  data: function data() {
    return {
      timer: null,
      day: '',
      time: '',
      weekday: ''
    };
  },
  created: function created() {
    var _this = this;
    this.timer = setInterval(function () {
      _this.day = (0, _dayjs.default)().format('YYYY年MM月DD日');
      _this.time = (0, _dayjs.default)().format('HH:mm:ss');
      _this.weekday = (0, _dayjs.default)().weekday();
    }, 1000);
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    formatWeekDay: function formatWeekDay(value) {
      switch (Number(value)) {
        case 1:
          return '星期一';
        case 2:
          return '星期二';
        case 3:
          return '星期三';
        case 4:
          return '星期四';
        case 5:
          return '星期五';
        case 6:
          return '星期六';
        case 7:
          return '星期日';
      }
    }
  }
};
exports.default = _default;