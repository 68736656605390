"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/core-js/modules/es6.array.iterator.js");
require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/core-js/modules/es6.promise.js");
require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/core-js/modules/es6.object.assign.js");
require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
var _Dict = _interopRequireDefault(require("./components/Dict"));
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _Permission = _interopRequireDefault(require("./components/Permission"));
require("./assets/styles/element-variables.scss");
require("./assets/styles/index.scss");
var _vueHighlightjs = _interopRequireDefault(require("vue-highlightjs"));
require("highlight.js/styles/atom-one-dark.css");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _routers = _interopRequireDefault(require("./router/routers"));
require("./assets/icons");
require("./router/index");
require("echarts-gl");
var _localStorage = _interopRequireDefault(require("@/utils/localStorage"));
require("./mqtt");
require("./directives");
require("./v-component");
//
// import mavonEditor from 'mavon-editor'
// import 'mavon-editor/dist/css/index.css'
// 数据字典
// 权限指令
// global css
// 代码高亮
// icon
// permission control
_vue.default.use(_localStorage.default);
_vue.default.use(_permission.default);
_vue.default.use(_vueHighlightjs.default);
_vue.default.use(_Permission.default);
_vue.default.use(_Dict.default);
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'small' // set element-ui default size
});

_vue.default.config.productionTip = false;
_vue.default.prototype.$eventBus = new _vue.default();
new _vue.default({
  el: '#app',
  router: _routers.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});