"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.function.name");
var _formSearch = _interopRequireDefault(require("@/components/common/form-search"));
var _formSearchItem = _interopRequireDefault(require("@/components/common/form-search/form-search-item"));
var _table = _interopRequireDefault(require("@/components/common/table"));
var _form = _interopRequireDefault(require("@/components/common/form"));
var _contextMenu = _interopRequireDefault(require("./components/common/context-menu"));
var _input = _interopRequireDefault(require("@/components/common/input"));
var _vue = _interopRequireDefault(require("vue"));
_vue.default.use(_contextMenu.default);
_vue.default.component(_formSearch.default.name, _formSearch.default);
_vue.default.component(_formSearchItem.default.name, _formSearchItem.default);
_vue.default.component(_table.default.name, _table.default);
_vue.default.component(_form.default.name, _form.default);
_vue.default.component(_input.default.name, _input.default);