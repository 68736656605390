"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
var _formSearchItem = _interopRequireDefault(require("./form-search-item"));
var _default2 = {
  name: 'FormSearch',
  components: (0, _defineProperty2.default)({}, _formSearchItem.default.name, _formSearchItem.default),
  props: {
    option: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      form: {}
    };
  },
  methods: {
    handleReset: function handleReset() {
      this.$set(this, 'form', {});
      this.$emit('reset', this.form);
    },
    handleSearch: function handleSearch() {
      this.$emit('search', this.form);
    },
    getSearchForm: function getSearchForm() {
      return this.form;
    },
    submit: function submit() {
      return false;
    },
    keyup: function keyup(e) {
      if (e.target.nodeName === 'INPUT') {
        this.handleSearch();
      }
    }
  }
};
exports.default = _default2;