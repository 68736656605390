"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRouterMap = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("../layout/index"));
var _index2 = _interopRequireDefault(require("../frontPage/index"));
_vue.default.use(_vueRouter.default);
var constantRouterMap = [
// {
//   path: '/',
//   redirect: '/login',
// },
{
  path: '/login',
  meta: {
    title: '登录',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/login'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/features/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  component: function component(resolve) {
    return require(['@/views/features/401'], resolve);
  },
  hidden: true
}, {
  path: '/redirect',
  component: _index.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/features/redirect'], resolve);
    }
  }]
}, {
  path: '/',
  component: _index2.default,
  redirect: '/frontPage/index',
  hidden: true,
  children: [{
    meta: {
      title: '首页',
      icon: 'user'
    },
    path: '/frontPage/index',
    component: function component(resolve) {
      return require(['@/views/frontPage/indexPage/index.vue'], resolve);
    }
  }]
}, {
  path: '/user',
  component: _index.default,
  redirect: 'noredirect',
  hidden: true,
  children: [{
    path: 'center',
    component: function component(resolve) {
      return require(['@/views/system/user/center'], resolve);
    },
    name: '个人中心',
    meta: {
      title: '个人中心',
      icon: 'user'
    }
  }]
}];
exports.constantRouterMap = constantRouterMap;
var _default = new _vueRouter.default({
  // mode: 'hash',
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});
exports.default = _default;