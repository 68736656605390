"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.item.hidden ? _c("div", {
    staticClass: "menu-wrapper"
  }, [_vm.hasOneShowingChild(_vm.item.children, _vm.item) && (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) && !_vm.item.alwaysShow ? [_vm.onlyOneChild.meta ? _c("app-link", {
    attrs: {
      to: _vm.resolvePath(_vm.onlyOneChild.path)
    }
  }, [_c("el-menu-item", {
    class: {
      "submenu-title-noDropdown": !_vm.isNest
    },
    attrs: {
      index: _vm.resolvePath(_vm.onlyOneChild.path)
    }
  }, [_c("item", {
    attrs: {
      icon: _vm.onlyOneChild.meta.icon || _vm.item.meta && _vm.item.meta.icon,
      title: _vm.onlyOneChild.meta.title
    }
  })], 1)], 1) : _vm._e()] : _c("el-submenu", {
    ref: "subMenu",
    attrs: {
      index: _vm.resolvePath(_vm.item.path),
      "popper-append-to-body": ""
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm.item.meta ? _c("item", {
    attrs: {
      icon: _vm.item.meta && _vm.item.meta.icon,
      title: _vm.item.meta.title
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm._l(_vm.item.children, function (child) {
    return _c("sidebar-item", {
      key: child.path,
      staticClass: "nest-menu",
      attrs: {
        "is-nest": true,
        item: _objectSpread(_objectSpread({}, child), {}, {
          meta: _objectSpread(_objectSpread({}, child.meta), {}, {
            icon: null
          })
        }),
        "base-path": _vm.resolvePath(child.path)
      }
    });
  })], 2)], 2) : _vm._e();
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;