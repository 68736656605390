"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dropdown", {
    staticClass: "user-drop",
    attrs: {
      trigger: "hover"
    },
    on: {
      command: _vm.handleCommand
    }
  }, [_c("span", {
    staticClass: "el-dropdown-link"
  }, [_vm._v("\n    欢迎您，" + _vm._s(_vm.user.username) + "\n    "), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _vm._v(" "), _c("el-dropdown-menu", {
    staticClass: "user-drop-menu",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.dropMenus, function (item) {
    return _c("el-dropdown-item", {
      key: item.value,
      attrs: {
        command: item.value
      }
    }, [_vm._v("\n      " + _vm._s(item.label) + "\n    ")]);
  }), 1)], 1);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;