exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"menuText": "#323232",
	"menuActiveText": "#1890ff",
	"subMenuActiveText": "#1890ff",
	"menuBg": "#fff",
	"menuHover": "#fff",
	"subMenuBg": "#fff",
	"subMenuHover": "#fff",
	"sideBarWidth": "208px"
};