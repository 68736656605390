"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.regexp.replace");
var _vue = _interopRequireDefault(require("vue"));
function getLoadingDom() {
  var loadingModal = document.createElement('div');
  loadingModal.setAttribute('style', 'position:absolute;width:100%;height:100%;left:0px;top:0px;pointer-events:none;z-index:999;display:flex;align-items:center;justify-content:center');
  loadingModal.setAttribute('class', 'v-button-wait');
  var loading = document.createElement('i');
  loading.setAttribute('class', 'el-icon-loading wait-loading');
  loading.style.fontSize = '16px';
  loading.style.color = '#ffffff';
  loadingModal.appendChild(loading);
  return loadingModal;
}
_vue.default.directive('wait', {
  bind: function bind(el, binding, vnode, oldVnode) {
    if (binding.value) {
      el.className += ' wait-is-disabled';
      var dom = getLoadingDom();
      el.appendChild(dom);
    }
  },
  update: function update(el, binding) {
    if (binding.value) {
      el.className += ' wait-is-disabled';
      var dom = getLoadingDom();
      el.appendChild(dom);
    } else {
      el.className = el.className.replace(' wait-is-disabled', '');
      var _dom = el.querySelector('.v-button-wait');
      _dom && _dom.remove();
    }
  },
  unbind: function unbind(el, binding) {
    if (!binding.value) {
      el.className = el.className.replace(' wait-is-disabled', '');
      var dom = el.querySelector('.v-button-wait');
      dom && dom.remove();
    }
  }
});