"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _logo = _interopRequireDefault(require("@/assets/images/logo.png"));
var _logoSmall = _interopRequireDefault(require("@/assets/images/logo-small.png"));
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '智慧农业云平台',
      logo: _logo.default,
      LogoSmall: _logoSmall.default
    };
  }
};
exports.default = _default;