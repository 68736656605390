"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var _default = {
  name: 'form-search-item',
  props: {
    config: {
      type: Object,
      default: {}
    }
  },
  render: function render(h) {
    var _this = this;
    var renderOption = function renderOption(config) {
      var tag = config.tag,
        _config$options = config.options,
        options = _config$options === void 0 ? [] : _config$options,
        _config$labelKey = config.labelKey,
        labelKey = _config$labelKey === void 0 ? 'label' : _config$labelKey,
        _config$valueKey = config.valueKey,
        valueKey = _config$valueKey === void 0 ? 'value' : _config$valueKey;
      if (tag === 'select') {
        return options.map(function (item) {
          return h('el-option', {
            props: {
              label: item[labelKey],
              value: item[valueKey],
              disabled: item.disabled
            }
          });
        });
      }
      if (tag === 'radio-group') {
        return options.map(function (item) {
          return h('el-radio', {
            props: {
              label: item.value
            }
          }, [item.label]);
        });
      }
      return [];
    };
    return h("el-".concat(this.config.tag), {
      attrs: _objectSpread({}, this.config.attrs),
      style: _objectSpread({}, this.config.style),
      props: _objectSpread(_objectSpread(_objectSpread({}, this.$attrs), this.config.props), {}, {
        clearable: true
      }),
      on: _objectSpread(_objectSpread({}, this.config.listeners || {}), {}, {
        input: function input(e) {
          _this.$listeners.input && _this.$listeners.input(e);
          (_this.config.listeners || {}).input && (_this.config.listeners || {}).input(e);
        },
        change: function change(e) {
          _this.$listeners.change && _this.$listeners.change(e);
          (_this.config.listeners || {}).change && (_this.config.listeners || {}).change(e);
        }
      }),
      slots: _objectSpread({}, this.config.slots)
    }, renderOption(this.config));
  }
};
exports.default = _default;