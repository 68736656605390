"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
var _axios = _interopRequireDefault(require("axios"));
var _routers = _interopRequireDefault(require("@/router/routers"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("../store"));
var _auth = require("@/utils/auth");
var _settings = _interopRequireDefault(require("@/settings"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
// 创建axios实例
var service = _axios.default.create({
  baseURL: '/',
  // api 的 base_url
  timeout: _settings.default.timeout // 请求超时时间
});

// request拦截器
service.interceptors.request.use(function (config) {
  if ((0, _auth.getToken)()) {
    config.headers['Authorization'] = (0, _auth.getToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  config.headers['Content-Type'] = 'application/json';
  return config;
}, function (error) {
  Promise.reject(error);
});

// response 拦截器
service.interceptors.response.use(function (response) {
  return response.data;
}, function (error) {
  // 兼容blob下载出错json提示
  if (error.response.data instanceof Blob && error.response.data.type.toLowerCase().indexOf('json') !== -1) {
    var reader = new FileReader();
    reader.readAsText(error.response.data, 'utf-8');
    reader.onload = function (e) {
      var errorMsg = JSON.parse(reader.result).message;
      _elementUi.Notification.error({
        title: errorMsg,
        duration: 5000
      });
    };
  } else {
    var code = 0;
    try {
      code = error.response.data.status;
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        _elementUi.Notification.error({
          title: '网络请求超时',
          duration: 5000
        });
        return Promise.reject(error);
      }
    }
    console.log(code);
    if (code) {
      if (code === 401) {
        _store.default.dispatch('LogOut').then(function () {
          // 用户登录界面提示
          _jsCookie.default.set('point', 401);
          var errorMsg = error.response.data.message;
          _elementUi.Notification.error({
            title: errorMsg,
            duration: 5000
          });
          // location.reload()
        });
      } else if (code === 403) {
        _routers.default.push({
          path: '/401'
        });
      } else {
        var errorMsg = error.response.data.message;
        if (errorMsg !== undefined) {
          _elementUi.Notification.error({
            title: errorMsg,
            duration: 5000
          });
        }
      }
    } else {
      _elementUi.Notification.error({
        title: '接口请求失败',
        duration: 5000
      });
    }
  }
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;