"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createShareWorker = exports.closeShareWorker = void 0;
require("core-js/modules/es6.number.constructor");
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("./store"));
var shareWorker;
var createShareWorker = function createShareWorker() {
  shareWorker = shareWorker || new SharedWorker('/worker.js');
  shareWorker.port.onmessage = function (e) {
    if (e.data !== 'ping') {
      var _store$getters$user = _store.default.getters.user,
        id = _store$getters$user.id,
        pId = _store$getters$user.pId;
      var data = JSON.parse(JSON.parse(e.data).msg);
      if (Number(data.userId) === (pId || id)) {
        (0, _elementUi.Notification)({
          title: '系统警告',
          message: "\u6C14\u8C61\u7AD9\u3010".concat(data.wsName, "\u3011-\u4F20\u611F\u5668\u7F16\u53F7\u3010").concat(data.eNum, "\u3011\u89E6\u53D1\u62A5\u8B66\u4E8B\u4EF6\uFF0C\u62A5\u8B66\u5185\u5BB9\u4E3A").concat(data.text, "\uFF0C\u8BF7\u53CA\u65F6\u5904\u7406\uFF01"),
          type: 'warning',
          duration: 600000,
          showClose: true,
          position: 'bottom-right'
        });
      }
    }
  };
  shareWorker.port.start();
};
exports.createShareWorker = createShareWorker;
var closeShareWorker = function closeShareWorker() {
  shareWorker && shareWorker.port.close();
};
exports.closeShareWorker = closeShareWorker;