"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _contextMenuItem = _interopRequireDefault(require("./context-menu-item"));
var _utils = require("./utils");
require("./style.scss");
var contextMenu = {
  name: 'v-contextmenu',
  props: {
    appendToBody: {
      type: Boolean,
      default: true
    },
    menus: {
      type: Array,
      default: []
    },
    zIndex: {
      type: Number,
      default: 9999
    },
    submenuDelay: {
      type: Number,
      default: 100
    },
    placement: {
      type: Array,
      validator: function validator(value) {
        return ['top', 'bottom', 'auto'].indexOf(value[0]) !== -1 && ['left', 'right', 'auto'].indexOf(value[1]) !== -1;
      },
      default: function _default() {
        return ['auto', 'auto'];
      }
    },
    _root: {
      type: Object,
      default: undefined
    }
  },
  components: {
    contextMenuItem: _contextMenuItem.default
  },
  data: function data() {
    return {
      focusIndex: -1,
      position: {
        left: -9999,
        top: -9999
      }
    };
  },
  mounted: function mounted() {
    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
    if (!this._root) {
      document.addEventListener('click', this.globalClickListener, true);
      document.addEventListener('wheel', this.globalClickListener, true);
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (this.appendToBody) {
      document.body.removeChild(this.$el);
    }
    document.removeEventListener('click', this.globalClickListener, true);
    document.removeEventListener('wheel', this.globalClickListener, true);
  },
  methods: {
    propver: function propver(e) {
      var x = e.x,
        y = e.y;
      this.position = (0, _utils.getPosition)({
        x: x,
        y: y
      }, this.$el);
      this.show();
    },
    globalClickListener: function globalClickListener(e) {
      this.hide();
    },
    show: function show() {
      this.$el.style.display = 'block';
    },
    hide: function hide() {
      this.$el.style.display = 'none';
    },
    renderMenuItem: function renderMenuItem(menus) {
      var h = this.$createElement;
      return menus.map(function (menuItem) {
        return h(_contextMenuItem.default, {
          "attrs": {
            "menuItem": menuItem
          }
        });
      });
    }
  },
  render: function render(h) {
    var menus = this.menus,
      _root = this._root,
      focusIndex = this.focusIndex,
      position = this.position;
    var top = position.top,
      left = position.left;
    return h("div", {
      "class": "v-contextmenu--wrapper",
      "style": {
        top: "".concat(top, "px"),
        left: "".concat(left, "px"),
        display: 'none',
        'z-index': this.zIndex
      }
    }, [h("ul", {
      "class": "v-contextmenu-ul",
      "attrs": {
        "tabindex": "-1"
      }
    }, [this.renderMenuItem(menus)])]);
  }
};
var _default2 = contextMenu;
exports.default = _default2;