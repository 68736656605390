"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "dateTime"
  }, [_c("div", {
    staticClass: "day"
  }, [_vm._v(_vm._s(_vm.day))]), _vm._v(" "), _c("div", {
    staticClass: "weekday"
  }, [_vm._v(_vm._s(_vm.formatWeekDay(_vm.weekday)))]), _vm._v(" "), _c("div", {
    staticClass: "time"
  }, [_vm._v(_vm._s(_vm.time))])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;