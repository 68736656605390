"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.array.find");
var _dateTime = _interopRequireDefault(require("./conponents/date-time"));
var _userDrop = _interopRequireDefault(require("./conponents/user-drop"));
var _default = {
  name: "frontPageLayout",
  components: {
    dataTime: _dateTime.default,
    userDrop: _userDrop.default
  },
  mounted: function mounted() {
    // this.initWebSocket();
  },
  data: function data() {
    return {};
  },
  computed: {
    menus: function menus() {
      var asyncRoute = this.$store.getters.addRouters.find(function (item) {
        return item.path === "/frontPage";
      }) || [];
      return [{
        title: "首页",
        path: "/frontPage/index"
      }].concat((0, _toConsumableArray2.default)((asyncRoute.children || []).map(function (item) {
        return {
          title: item.meta.title,
          path: asyncRoute.path + "/" + item.path
        };
      })));
    },
    title: function title() {
      var user = this.$store.getters.user;
      return user.enterprise.screenName || "智慧农业云系统";
    }
  },
  methods: {}
};
exports.default = _default;