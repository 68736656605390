// const mqtt = require('mqtt');

// const options = {
//     clean: true,
//     connectTimeout: 4000,
//     clientId: 'emqx_test',
//     username: 'admin',
//     password: 'public',
// }

// const client = mqtt.connect('ws://121.89.161.133:1883', options)

// client.on('connect', function () {
//   console.log('Connected')
//   client.subscribe('test', function (err) {
//     if (!err) {
//       client.publish('test', 'Hello mqtt')
//     }
//   })
// })
"use strict";