"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _contextMenu = _interopRequireDefault(require("./context-menu"));
function install(Vue) {
  Vue.component(_contextMenu.default.name, _contextMenu.default);
}
var _default = {
  contextMenu: _contextMenu.default,
  install: install
};
exports.default = _default;