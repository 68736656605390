"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "front-page-layout"
  }, [_c("div", {
    staticClass: "layout-header"
  }, [_c("div", {
    staticClass: "header-title"
  }, [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), _c("div", {
    staticClass: "header-content"
  }, [_c("div", {
    staticClass: "date-time"
  }, [_c("dataTime")], 1), _vm._v(" "), _c("div", {
    staticClass: "user-info"
  }, [_c("userDrop")], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "layout-body"
  }, [_c("div", {
    staticClass: "layout-aside"
  }, _vm._l(_vm.menus, function (item) {
    return _c("div", {
      key: item.path,
      class: {
        "menu-item": true,
        "menu-item-active": item.path === _vm.$route.path
      }
    }, [_c("router-link", {
      attrs: {
        to: item.path
      }
    }, [_c("span", [_vm._v(_vm._s(item.title))])])], 1);
  }), 0), _vm._v(" "), _c("div", {
    staticClass: "layout-min"
  }, [_c("router-view")], 1)])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;
render._withStripped = true;