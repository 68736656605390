"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPosition = getPosition;
function getPosition(_ref, el) {
  var x = _ref.x,
    y = _ref.y;
  var top = y + 15,
    left = x + 15;
  var _document$body = document.body,
    offsetWidth = _document$body.offsetWidth,
    offsetHeight = _document$body.offsetHeight;
  var clientWidth = el.clientWidth,
    clientHeight = el.clientHeight;
  if (left + clientWidth > offsetWidth) {
    left = left - clientWidth - 30;
  }
  if (top + clientHeight > offsetHeight) {
    top = top - clientHeight - 30;
  }
  return {
    top: top,
    left: left
  };
}