"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _babelHelperVueJsxMergeProps = _interopRequireDefault(require("@vue/babel-helper-vue-jsx-merge-props"));
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("./style.scss");
var _xlsx = _interopRequireDefault(require("xlsx"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
console.log(_xlsx.default);
var _default2 = {
  name: 'page-table',
  props: {
    tableCloumns: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    asyncData: {
      type: Function
    },
    tableOption: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      tableData: [],
      loading: false,
      pagination: {
        background: true,
        pageSize: 10,
        total: 0,
        currentPage: 1,
        layout: 'prev, pager, next, sizes,jumper',
        hideOnSinglePage: false
      },
      customSort: {}
    };
  },
  computed: {
    downLoadDisabled: function downLoadDisabled() {
      return !this.tableData.length;
    }
  },
  created: function created() {
    this.initData();
    this.mergePagination();
  },
  methods: {
    initData: function initData() {
      var _this = this;
      this.loading = true;
      var query = this.getQuery();
      this.asyncData(query).then(function (res) {
        var content = res.content,
          totalElements = res.totalElements;
        _this.tableData = content;
        _this.pagination.total = totalElements;
        if (!content.length && totalElements !== 0) {
          _this.pagination.currentPage = _this.pagination.currentPage - 1;
          _this.initData();
        }
      }).finally(function () {
        _this.loading = false;
      });
    },
    refresh: function refresh() {
      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      if (page) {
        this.pagination.currentPage = page;
      }
      this.initData();
    },
    mergePagination: function mergePagination() {
      var _this$tableOption$pag = this.tableOption.pagination,
        pagination = _this$tableOption$pag === void 0 ? {} : _this$tableOption$pag;
      if (pagination instanceof Object) {
        Object.assign(this.pagination, _objectSpread({}, pagination));
      } else {
        throw new Error('pagination类型错误');
      }
    },
    getQuery: function getQuery() {
      var _this$pagination = this.pagination,
        _this$pagination$page = _this$pagination.pageSize,
        size = _this$pagination$page === void 0 ? 10 : _this$pagination$page,
        _this$pagination$curr = _this$pagination.currentPage,
        page = _this$pagination$curr === void 0 ? 1 : _this$pagination$curr;
      var _this$customSort = this.customSort,
        order = _this$customSort.order,
        prop = _this$customSort.prop;
      return {
        size: size,
        page: page - 1,
        order: order,
        prop: prop
      };
    },
    handleSizeChange: function handleSizeChange(pageSize) {
      this.pagination.pageSize = pageSize;
      this.initData();
    },
    handleCurrentChange: function handleCurrentChange(currentPage) {
      this.pagination.currentPage = currentPage;
      this.initData();
    },
    sortChange: function sortChange($event) {
      this.customSort = $event;
      this.initData();
    },
    getTableHeight: function getTableHeight() {
      return 'calc(100% - 104px)';
      // if (this.$slots.leftBtn || this.$slots.rightBtn) {
      // }
      // return 'calc(100% - 48px)'
    },
    handleDownload: function handleDownload() {
      var _this2 = this;
      var tableCloumns = this.tableCloumns,
        _this$fileName = this.fileName,
        fileName = _this$fileName === void 0 ? 'sheet' : _this$fileName;
      var columns = tableCloumns.filter(function (item) {
        return item.label !== '操作' && item.label !== '序号';
      });
      var header = columns.reduce(function (obj, item) {
        obj[item.property] = item.label;
        return obj;
      }, {});
      var downData = this.tableData.map(function (item, index) {
        return columns.reduce(function (obj, col) {
          obj[col.property] = _this2.handleFormatDownLoad(item, index, col);
          return obj;
        }, {});
      });
      var fileData = _xlsx.default.utils.json_to_sheet([header].concat((0, _toConsumableArray2.default)(downData)), {
        skipHeader: true
      });
      var workbook = _xlsx.default.utils.book_new();
      _xlsx.default.utils.book_append_sheet(workbook, fileData);
      _xlsx.default.writeFile(workbook, "".concat(fileName, ".xlsx"));
    },
    handleFormatDownLoad: function handleFormatDownLoad(r, i, col) {
      var formatDownLoad = col.formatDownLoad,
        formatter = col.formatter,
        property = col.property;
      if (formatDownLoad) {
        return formatDownLoad(r, i);
      }
      if (formatter) {
        return formatter(r, i);
      }
      return r[property];
    }
  },
  render: function render(h) {
    var _this3 = this;
    return h("div", {
      "class": 'page--table'
    }, [h("div", {
      "class": 'page--table--header'
    }, [h("div", {
      "class": 'page--table--header--left'
    }, [this.$slots.leftBtn]), h("div", {
      "class": 'page--table--header--right'
    }, [this.$slots.rightBtn, h("el-button", {
      "attrs": {
        "type": 'defaut',
        "icon": "el-icon-download",
        "disabled": this.downLoadDisabled
      },
      "on": {
        "click": this.handleDownload
      }
    }, ["\u5BFC\u51FA\u8868\u683C"])])]), h("el-table", (0, _babelHelperVueJsxMergeProps.default)([{}, {
      ref: 'table',
      attrs: _objectSpread(_objectSpread({}, this.$attrs), {}, {
        height: this.getTableHeight()
      }),
      props: _objectSpread(_objectSpread({}, this.$attrs), {}, {
        data: this.tableData
      }),
      on: _objectSpread(_objectSpread({}, this.$listeners), {}, {
        'sort-change': function sortChange($event) {
          _this3.sortChange($event);
          _this3.$listeners['sort-change'] && _this3.$listeners['sort-change']($event);
        }
      }),
      directives: [{
        name: 'loading',
        value: this.loading
      }]
    }]), [this.tableCloumns.map(function (column) {
      return h("el-table-column", (0, _babelHelperVueJsxMergeProps.default)([{}, {
        props: _objectSpread({}, column),
        scopedSlots: {
          default: column.scopedSlots ? function ($event) {
            return column.scopedSlots($event);
          } : null
        }
      }]));
    })]), h("div", {
      "class": 'page--table--pagination'
    }, [h("el-pagination", {
      "props": _objectSpread({}, _objectSpread({}, this.pagination)),
      "on": _objectSpread({}, {
        'current-change': function currentChange(e) {
          _this3.handleCurrentChange(e);
        },
        'size-change': function sizeChange(e) {
          _this3.handleSizeChange(e);
        }
      })
    })])]);
  }
};
exports.default = _default2;