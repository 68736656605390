"use strict";

module.exports = {
  /**
   * @description 网站标题
   */
  title: '智慧物联网',
  /**
   * @description 是否显示 tagsView
   */
  tagsView: false,
  /**
   * @description 固定头部
   */
  fixedHeader: true,
  /**
   * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
   */
  tokenCookieExpires: 1,
  /**
   * @description 记住密码状态下的密码在Cookie中存储的天数，默认1天s
   */
  passCookieExpires: 1,
  /**
   * @description 是否只保持一个子菜单的展开
   */
  uniqueOpened: true,
  /**
   * @description token key
   */
  TokenKey: 'EL-ADMIN-TOEKN',
  /**
  * @description 萤石账号appkey
  */
  YHAppKey: '027004a60b0144f3a52e1ce3c88923de',
  /**
  * @description 萤石账号secret
  */
  YHSecret: '235793f61ebe1a5bd27480478597c9d1',
  /**
   * @description 萤石视频接口token
   */
  YHTokenKey: 'YH-TOKEN',
  /**
   * @description 请求超时时间，毫秒（默认2分钟）
   */
  timeout: 1200000,
  /**
   * @description 是否显示logo
   */
  sidebarLogo: true,
  /**
   * 是否显示设置的底部信息
   */
  showFooter: false,
  /**
   * 底部文字，支持html语法
   */
  footerTxt: '© 2019 Zheng Jie <a href="http://www.apache.org/licenses/LICENSE-2.0" target="_blank">Apache License 2.0</a>',
  /**
   * 备案号
   */
  caseNumber: '浙ICP备18005431号'
};