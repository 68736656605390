"use strict";

var _interopRequireDefault = require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _babelHelperVueJsxMergeProps = _interopRequireDefault(require("@vue/babel-helper-vue-jsx-merge-props"));
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/agriculture_lot_admin_4kwG/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var _default2 = {
  name: 'DialogForm',
  props: {
    option: {
      type: Object
    },
    createFun: Function,
    editFun: Function
  },
  data: function data() {
    return {
      visible: false,
      edit: false,
      loading: false,
      form: {}
    };
  },
  computed: {
    title: function title() {
      var _this$option$dialogOp = this.option.dialogOption,
        title = _this$option$dialogOp.title,
        importTitle = _this$option$dialogOp.importTitle;
      if (importTitle) return importTitle;
      return (!this.edit ? '新增' : '编辑') + title;
    }
  },
  methods: {
    createModel: function createModel() {
      var _this = this;
      var model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.edit = false;
      this.visible = true;
      this.$set(this, 'form', model);
      this.$nextTick(function () {
        _this.$refs.form.clearValidate();
      });
    },
    editModel: function editModel(model) {
      var _this2 = this;
      this.edit = true;
      this.visible = true;
      this.$set(this, 'form', model);
      this.$nextTick(function () {
        _this2.$refs.form.clearValidate();
      });
    },
    submit: function submit() {
      var _this3 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          var fun = _this3.edit ? _this3.editFun : _this3.createFun;
          fun(_objectSpread({}, _this3.form)).then(function () {
            _this3.$message.success("".concat(_this3.title, "\u6210\u529F"));
            _this3.$emit('success', _objectSpread({}, _this3.form));
            _this3.visible = false;
          }).finally(function () {
            _this3.loading = false;
          });
        }
      });
    }
  },
  render: function render() {
    var _this4 = this;
    var h = arguments[0];
    var _this$option = this.option,
      formOption = _this$option.formOption,
      dialogOption = _this$option.dialogOption,
      formItemList = _this$option.formItemList;
    return h("el-dialog", (0, _babelHelperVueJsxMergeProps.default)([{}, {
      props: _objectSpread(_objectSpread({
        visible: this.visible
      }, dialogOption), {}, {
        title: this.title,
        'close-on-click-modal': false
      }),
      on: {
        'update:visible': function updateVisible(visible) {
          return _this4.visible = visible;
        },
        closed: function closed() {
          _this4.$refs.form.resetFields();
          _this4.$emit('closed');
        }
      },
      scopedSlots: {
        default: function _default() {
          return h("el-form", (0, _babelHelperVueJsxMergeProps.default)([{}, {
            ref: 'form',
            props: _objectSpread({
              model: _this4.form
            }, formOption)
          }]), [formItemList.map(function (formItem) {
            return h("el-row", {
              "key": formItem.key
            }, [h("el-col", {
              "props": _objectSpread({}, _objectSpread({}, formItem.colProps))
            }, [h("el-form-item", {
              "props": _objectSpread({}, _objectSpread({}, formItem.formItemProps))
            }, [h("form-search-item", {
              "props": _objectSpread({}, {
                config: _objectSpread(_objectSpread({}, formItem.comProps), {}, {
                  attrs: _objectSpread(_objectSpread({}, formItem.comProps.attrs || {}), {}, {
                    value: _this4.form[formItem.key]
                  })
                })
              }),
              "on": _objectSpread({}, {
                input: function input(value) {
                  _this4.$set(_this4.form, formItem.key, value);
                }
              })
            })])])]);
          })]);
        }
      }
    }]), [h("template", {
      "slot": 'footer'
    }, [h("div", {
      "class": 'dialog-footer'
    }, [h("el-button", {
      "on": {
        "click": function click() {
          return _this4.visible = false;
        }
      }
    }, ["\u53D6 \u6D88"]), h("el-button", {
      "attrs": {
        "type": 'primary',
        "loading": this.loading
      },
      "on": {
        "click": this.submit
      }
    }, ["\u786E \u5B9A"])])])]);
  }
};
exports.default = _default2;