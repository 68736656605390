"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.array.find");
var _default = {
  name: "userDrop",
  data: function data() {
    return {
      dropMenus: [{
        label: "智慧后台",
        value: "system"
      }, {
        label: "退出登录",
        value: "layout"
      }]
    };
  },
  computed: {
    user: function user() {
      return this.$store.getters.user;
    },
    addRouters: function addRouters() {
      return this.$store.getters.addRouters;
    }
  },
  methods: {
    handleCommand: function handleCommand(value) {
      switch (value) {
        case "system":
          this.routePush();
          break;
        case "layout":
          this.logout();
          break;
      }
    },
    logout: function logout() {
      var _this = this;
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this.$store.dispatch("LogOut").then(function () {
          location.reload();
        });
      });
    },
    routePush: function routePush() {
      if (!this.addRouters.length) {
        this.$message.error("您没有后台的权限，请分配权限后再访问");
      } else {
        var getRoute = function getRoute(routeList) {
          var routePath;
          var route = routeList.find(function (item) {
            return !item.hidden;
          });
          if (!route) return false;
          routePath = route.path.includes("/") ? route.path : "/" + route.path;
          if (route.children && route.children.length) {
            routePath = routePath + getRoute(route.children);
          }
          return routePath;
        };
        var routePath = getRoute(this.addRouters);
        if (!routePath) {
          this.$message.error("您没有后台的权限，请分配权限后再访问");
        } else {
          window.open(routePath);
        }
      }
    }
  }
};
exports.default = _default;